




import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { dispatchCreateSymptom } from '@/store/crud/actions';
import SymptomForm from '@/components/forms/SymptomForm.vue';
import { ICreateSymptom } from '@/interfaces/symptoms';


@Component({ components: { SymptomForm } })
export default class AdminExercise extends AppComponent {
  @Prop({ type: String, required: false }) public symptomId: string;

  public symptomUpdated() {
    this.$router.push('/main/content/symptoms');
  }

  public async symptomCreated(symptomPayload: ICreateSymptom) {
    if (symptomPayload && Object.keys(symptomPayload).length > 0) {
      const result = await dispatchCreateSymptom(this.$store, symptomPayload);
      if (result) {
          this.$router.push('/main/content/symptoms');
      }
    }
  }

  public cancel() {
    this.$router.push('/main/content/symptoms');
  }
}


















































import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICreateSymptom, ISymptom, IUpdateSymptom } from '@/interfaces/symptoms';
import { dispatchGetSymptomById, dispatchUpdateSymptom } from '@/store/crud/actions';

@Component({})
export default class SymptomForm extends AppComponent {
  @Prop({ type: String, required: false, default: null }) public symptomId: string;

  public valid = false;
  /*
    Symptom data
  */
  public name: string = '';
  public symptomType: string = '';
  public description: string = '';

  public symptom: ISymptom | null = null;
  public forceCreation: boolean = false;
  public busId: number = 0;

  public async initiView() {
    if (!this.isCreation && this.symptomId && this.symptomId.length > 0) {
      this.symptom = await dispatchGetSymptomById(this.$store, this.symptomId );
      if (!this.symptom || Object.keys(this.symptom).length === 0) {
        this.forceCreation = true; // force creation if symptom is not found or error with the API
      }
    }
    this.reset();
  }

  /**
   * Resets the form.
   * if the symptom id is provided, this will be an edit form so existing data is injected into the form
   * otherwise, this will be a creation form and the data will be empty
   */
  public reset() {
    if (this.isCreation) {
      this.name = '';
      this.symptomType = '';
      this.description = '';
    } else {
      if (this.symptom) {
        this.name = this.symptom.name || '';
        this.symptomType = this.symptom.symptomType || '';
        this.description = this.symptom.description || '';
      } else {
        // weird  case, we just force creation and re-call the function
        this.forceCreation = true;
        this.reset();
        return;
      }
    }
    this.$validator.reset();
  }

  public cancel() {
    this.$emit('symptom:cancelled');
  }

  public get isCreation() {
    return this.symptomId === null || this.forceCreation;
  }

  public async mounted() {
    this.initiView();
    this.busId = this.$bus.$on('symptom:reset', this.initiView);
  }

  public beforeDestroy() {
    this.$bus.$off('symptom:reset', this.busId);
  }



  /**
   * Updates the symptom
   * Checks if the form variables are valid / available
   * and only sends the data if it changed from the original data
   * @Argument {boolean} silent - false by default, if true it emits an
   * symptom:updated event to notify parent components that a new symptom has been
   * created
   */
  public async updateSymptom(silent = false) {
    const uploadPayload: IUpdateSymptom = {};
    if (this.symptom && this.symptom.id) {
      this.setFieldIfExistAndValueChanged(uploadPayload, 'name', this.name, this.symptom.name);
      this.setFieldIfChanges(uploadPayload, 'symptomType', this.symptomType, this.symptom.symptomType);
      this.setFieldIfChanges(uploadPayload, 'description', this.description, this.symptom.description);

      if (Object.keys(uploadPayload).length > 0) {
        const result = await dispatchUpdateSymptom(
          this.$store, { symptomId: this.symptom.id, symptom: uploadPayload });
        if (result) {
          if (!silent) {
            this.$emit('symptom:updated', result);
          }
        } else {
          if (!silent) {
            this.toast('Error while updating symptom', true);
          }
        }
      }
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.isCreation) {
        const protoSymptom: ISymptom = {
          name: this.name,
          symptomType: this.symptomType,
          description: this.description,
        };
        // sanitize creation data
        if (!this.hasValue(protoSymptom.name)) {
          this.toast('Name is required', true); // name is required
          return;
        }
        if (!this.hasValue(protoSymptom.symptomType)) { delete protoSymptom.symptomType; }
        if (!this.hasValue(protoSymptom.description)) { delete protoSymptom.description; }
        const createSymptom: ICreateSymptom = protoSymptom as ICreateSymptom;
        this.$emit('symptom:created', createSymptom);
      } else {
        this.updateSymptom();
      }
    }
  }
}
